import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDraggableModal',{ref:_vm.modal_name,attrs:{"name":_vm.modal_name,"max-height":"80vh","width":"70vw","fullscreen":_vm.$vuetify.breakpoint.xsOnly}},[_c(VCard,{attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('BaseModal',[_c(VToolbar,{staticClass:"draggable_selector",attrs:{"slot":"header","dark":"","color":"primary","dense":"","flat":""},slot:"header"},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.reset.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.page.title))]),_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.loading,"text":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.save.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-content-save")])],1)],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c(VDivider),_c(VCardText,{staticClass:"pt-5"},[_c(VTextField,{staticClass:"ma-0",attrs:{"label":_vm.$store.getters.translate('name')},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$store.getters.translate('name'))),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("*")])])]},proxy:true}]),model:{value:(_vm.record.name),callback:function ($$v) {_vm.$set(_vm.record, "name", $$v)},expression:"record.name"}}),_c(VSelect,{staticClass:"ma-0",attrs:{"label":_vm.$store.getters.translate('permissions'),"items":_vm.$lodash.sortBy(_vm.permissions),"item-text":"label","item-value":"name","chips":"","deletable-chips":"","small-chips":"","multiple":"","clearable":"","append-outer-icon":"mdi-form-select"},on:{"click:append-outer":_vm.selectAll},model:{value:(_vm.record.permissions),callback:function ($$v) {_vm.$set(_vm.record, "permissions", $$v)},expression:"record.permissions"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }