<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click.stop="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn :disabled="loading" text small @click.stop="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text class="pt-5">
            <v-text-field
                class="ma-0"
                :label="$store.getters.translate('name')"
                v-model="record.name">
              <template #label>{{ $store.getters.translate('name') }}<span class="red--text"><strong>*</strong></span></template>
            </v-text-field>
            <v-select
                class="ma-0"
                :label="$store.getters.translate('permissions')"
                v-model="record.permissions"
                :items="$lodash.sortBy(permissions)"
                item-text="label"
                item-value="name"
                chips
                deletable-chips
                small-chips
                multiple
                clearable
                append-outer-icon="mdi-form-select"
                @click:append-outer="selectAll"/>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: { BaseModal, BaseDraggableModal },
  props: ["modal_name"],
  data() {
    return {
      page: {
        title: this.$store.getters.translate("create_role"),
        name: "roles",
        model: "role",
      },
      record: {
        name: null,
        permissions: [],
      },
      permissions: [],
      loading: false,
    };
  },
  created() {
    this.load();
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/permissions")
          .then((response) => {
            this.permissions = [];
            response.data.forEach((option) => {
              this.permissions.push({
                name: option.name,
                label: this.$store.getters.translate(option.name)
              });
            });
          })
          .catch((error) => {
            this.$toasted.error(error);
          });
    },
    async save() {
      if(!this.loading) {
        let usual_fields_required = [{name: 'name', mandatory: true}];
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, usual_fields_required)) {
          this.loading = true;
          let record_id = await helpFunctions.createModel(this.$toasted, this.page.name, this.record);
          if (record_id) {
            this.$emit("refresh");
            this.reset();
          }
          this.loading = false;
        }
      }
    },
    selectAll() {
      this.record.permissions = this.$lodash.map(this.permissions, "name");
    },
    reset() {
      this.record = {
        name: null,
        permissions: [],
      };
      this.closeModal(this.modal_name);
    },
  },
};
</script>
